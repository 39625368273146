<template>
  <v-row no-gutters v-if="dashboard">
    <v-col cols="12">
      <v-card>
        <v-card-title>{{ $t("Welcome") }}</v-card-title>
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="12" sm="6">
              <p
                class="pa-0 overflow-auto height-card-text"
                v-html="dashboard.WelcomeText.Value[currentLanguage]"
              ></p>
            </v-col>
            <v-col cols="12" sm="6">
              <v-layout justify-center class="ml-md-4">
                <v-img
                  contain
                  :lazy-src="
                    serverUrl +
                    '/images/' +
                    websiteId +
                    '/' +
                    dashboard.WelcomeImage.Value.Name +
                    '?token=' +
                    getUserToken
                  "
                  max-height="60vh"
                  max-width="auto"
                  :src="
                    serverUrl +
                    '/images/' +
                    websiteId +
                    '/' +
                    dashboard.WelcomeImage.Value.Name +
                    '?token=' +
                    getUserToken
                  "
                >
                </v-img>
              </v-layout>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card class="mt-4">
        <v-card-title>
          {{ $t("Image database") }}
        </v-card-title>
        <v-card-text>
          <a :href="dashboard.LinkImageDatabase.Value.Nl" target="_blank">
            <v-layout align-center class="mb-2">
              <p class="text-decoration-underline mb-0">
                {{ $t("Go to image database") }}
              </p>
              <v-icon class="ml-2"> mdi-arrow-right </v-icon>
            </v-layout>
          </a>
          <p class="mb-1">
            {{ $t("User") + ": " + dashboard.UserName.Value.Nl }}
          </p>
          <p class="mb-1">
            {{ $t("Password") + ": " + dashboard.Password.Value.Nl }}
          </p>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import request from "../../request";
import helpers from "../../helpers";
import { mapGetters } from "vuex";
import auth from "../../auth";

export default {
  data() {
    return {
      dashboard: null,
      serverUrl: helpers.getServerUrl(),
      websiteId: null,
    };
  },
  created() {
    request.get("/api/kdesign/b2b-dashboard", null, (res) => {
      this.websiteId = res.data.WebsiteId.$oid;
      this.dashboard = res.data.Data;
    });
  },
  computed: {
    ...mapGetters({
      websites: "allWebsites",
    }),
    getUserToken() {
      return auth.userToken();
    },
    currentLanguage: {
      get: function () {
        return this.$i18n.locale();
      },
    },
  },
  methods: {
    getDate() {
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      const yyyy = today.getFullYear();

      return mm + dd + yyyy;
    },
  },
};
</script>

<style scoped>
.height-card-text {
  max-height: 60vh;
}
</style>