var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.dashboard)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("Welcome")))]),_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('p',{staticClass:"pa-0 overflow-auto height-card-text",domProps:{"innerHTML":_vm._s(_vm.dashboard.WelcomeText.Value[_vm.currentLanguage])}})]),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-layout',{staticClass:"ml-md-4",attrs:{"justify-center":""}},[_c('v-img',{attrs:{"contain":"","lazy-src":_vm.serverUrl +
                  '/images/' +
                  _vm.websiteId +
                  '/' +
                  _vm.dashboard.WelcomeImage.Value.Name +
                  '?token=' +
                  _vm.getUserToken,"max-height":"60vh","max-width":"auto","src":_vm.serverUrl +
                  '/images/' +
                  _vm.websiteId +
                  '/' +
                  _vm.dashboard.WelcomeImage.Value.Name +
                  '?token=' +
                  _vm.getUserToken}})],1)],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mt-4"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("Image database"))+" ")]),_c('v-card-text',[_c('a',{attrs:{"href":_vm.dashboard.LinkImageDatabase.Value.Nl,"target":"_blank"}},[_c('v-layout',{staticClass:"mb-2",attrs:{"align-center":""}},[_c('p',{staticClass:"text-decoration-underline mb-0"},[_vm._v(" "+_vm._s(_vm.$t("Go to image database"))+" ")]),_c('v-icon',{staticClass:"ml-2"},[_vm._v(" mdi-arrow-right ")])],1)],1),_c('p',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t("User") + ": " + _vm.dashboard.UserName.Value.Nl)+" ")]),_c('p',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t("Password") + ": " + _vm.dashboard.Password.Value.Nl)+" ")])])],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }